import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { v4 } from "uuid"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BreakpointUp from "../components/Media/BreakpointUp"
import {
  BreadCrumb,
  Section,
  SectionTitle,
  SectionDescription,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import { PhoneIcon } from "../components/Icons"
import PrimaryButton from "../components/Button/PrimaryButton"
import CustomQuote from "../components/CustomQuote"
import ProductCard from "../components/ProductCard"
import Badge from "../components/Badge"
import QuotePopup from "../components/QuotePopup"

const HalfCircle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 -15px;
`
const HalfCircleItem = styled.div`
  width: 100%;
  position: relative;
  display: block;
  cursor: pointer;
  padding: 30px 15px 0;
  ${BreakpointUp.md`
    flex:0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`        
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
`
const HalfCircleThumb = styled.div`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    height: 46px;
    width: 4px;
    background-color: #fc0002;
    z-index: 2;
  }
`
const HalfCircleInset = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  height: 180px;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background: rgba(223, 227, 239, 0.4);
  padding: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 360px;
  .gatsby-image-wrapper {
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 30px solid rgba(255, 255, 255, 0.4);
      border-radius: 360px;
    }
  }
`

const HalfCircleCaption = styled.div`
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  & h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: 10px;
    ${BreakpointUp.md`
      font-size: 24px;
      line-height: 34px;
    `}
    ${BreakpointUp.lg`        
      font-size: 26px;
      line-height: 36px;
    `}
  }
  & p {
    line-height: 28px;
  }
`

const SectionHeading = styled.div`
  background-color: #0b619b;
  padding: 60px 0 50px 0;
  ${BreakpointUp.xl`
    padding: 0 0 50px 0;
  `}
  & h2 {
    margin-bottom: 20px;
  }
  & ul {
    margin-bottom: 0;
    justify-content: center;
    display: flex;
  }
`
const SectionBody = styled.div`
  background-color: #f2f4f9;
  padding: 45px 0;
`

const ListBadge = styled.ul`
  margin: 0 -6px 20px -6px;
  display: flex;
  flex-wrap: wrap;
`
const BadgeItem = styled.li`
  width: auto;
`
const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionItem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 60px;
  &:after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
    z-index: 0;
    width: 460px;
    height: 460px;
    border: 60px solid rgba(221, 228, 240, 0.5);
    @media (min-width: 1600px) {
      display: block;
      left: calc(100% - 350px);
    }
    @media (min-width: 1740px) {
      border: 80px solid rgba(221, 228, 240, 0.5);
      left: calc(100% - 290px);
    }
  }
`

const BackgroundImage = styled.div`
  position: relative;
  &:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    
    left:0;
    width: 100%;
    height: 50%;
    

    background: -moz-linear-gradient(top,  rgba(30,87,153,1) 0%, rgba(95,154,207,0.07) 100%);
    background: -webkit-linear-gradient(top,  rgba(30,87,153,1) 0%,rgba(95,154,207,0.07) 100%);
    background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(95,154,207,0.07) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#125f9acf',GradientType=0 );


  ${BreakpointUp.lg`	
    bottom: 0;
    left:auto;
    width: 50%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(125, 185, 232, 0) 0%,
      rgba(95, 154, 207, 0.7) 32%,
      rgba(30, 87, 153, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(125, 185, 232, 0) 0%,
      rgba(95, 154, 207, 0.7) 32%,
      rgba(30, 87, 153, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(125, 185, 232, 0) 0%,
      rgba(95, 154, 207, 0.7) 32%,
      rgba(30, 87, 153, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#1e5799',GradientType=1 );
    
  `}

  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  .container {
    padding: 0;
    ${BreakpointUp.xl` 
      max-width: 1920px;
    `}
  }
`
const TopHeaderWrap = styled.div`
  background-color:#fff;
  padding:30px 0;
  ${BreakpointUp.lg` 
    background-color:transparent;
    padding:0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    align-items: flex-start; 
    top:215px;
    transform: none;
  `}
`
const TopHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 -15px;
  ${BreakpointUp.lg`
    color: #fff;
    text-align: left;
  `}
  ${BreakpointUp.xl`
    margin:0 -30px;
  `}
`
const TopHeaderLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`
    padding:0 30px;
  `}
`
const TopHeaderRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;  
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`
    padding:0 30px;
  `}
`

const TopHeaderTitle = styled.h1`
  font-size: 34px;
  line-height: 38px;
  margin: 0 0 10px;
  ${BreakpointUp.sm`
    margin: 0 0 20px;
    `}
  ${BreakpointUp.md`
    font-size: 58px;
    line-height: 68px;
    `}
  ${BreakpointUp.lg`
   color: #fff;
    font-size: 62px;
    line-height: 72px;
    margin: 0 0 40px;
  `}
`

const TopHeaderText = styled.div`
  p {
    margin-bottom: 0;
    + p {
      margin-top: 20px;
    }
  }
`

const CategoryTypeTemplate = ({ data, location }) => {
  const filterItems = data.contentfulCategoryType.categoriesBelong
  const pageData = data.contentfulCategoryType
  const [currentType, setType] = useState(filterItems[0].name)
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const allProducts = data.allContentfulProduct.edges
  var currentProduct = []
  allProducts.forEach(function (item) {
    var flag = 0
    item.node.productApplications.forEach(function (node) {
      if (node.name === currentType) {
        flag = flag + 1
      }
    })
    item.node.productSubCategories.forEach(function (node) {
      if (node.name === currentType) {
        flag = flag + 1
      }
    })
    if (flag > 0) {
      currentProduct.push(item)
    }
  })
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb className="white">
        <div className="container">
          <Link to="/">Home / </Link>
          <span> {pageData.headingText}</span>
        </div>
      </BreadCrumb>
      <Section pt="0" pb="0" xpt="30px" xpb="0" bgColor="#fff">      
        <BackgroundImage>
          <GatsbyImage image={pageData.image.gatsbyImageData} alt={pageData.image.title} />
        </BackgroundImage>
        <TopHeaderWrap>
          <div className="container">
            <TopHeaderSection>
              <TopHeaderLeft><TopHeaderTitle>{pageData.headingText}</TopHeaderTitle></TopHeaderLeft>
              <TopHeaderRight>
                <TopHeaderText className="mb-20">
                  {pageData.bannerDescription && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          pageData.bannerDescription.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </TopHeaderText>
                <a href="tel:8666817846" aria-label="(866) 681-7846"><PrimaryButton icon={<PhoneIcon />} text="(866) 681-7846" /></a>
              </TopHeaderRight>
            </TopHeaderSection>
          </div>
        </TopHeaderWrap>
      </Section>
      <Section pt="0" pb="0" xpt="0" xpb="0" bgColor="#fff">
        <SectionHeading>
          <div className="container">
            <SectionTitle
              data-shadowtext={pageData.productsShadowText}
              color="#fff"
            >
              {pageData.productsHeading}
            </SectionTitle>
            <ListBadge>
              {filterItems.map(item => (
                <BadgeItem
                  className={
                    currentType === item.name
                      ? "badge-white active"
                      : "badge-white"
                  }
                  onClick={() => setType(item.name)}
                >
                  <Badge badgeText={item.name} />
                </BadgeItem>
              ))}
            </ListBadge>
          </div>
        </SectionHeading>
        <SectionBody>
          <div className="container">
            <SectionGrid>
              {currentProduct.map(item => (
                <SectionItem key={v4()}>
                  <ProductCard
                    data={item.node}
                    openQuoteModal={() => setQuoteVisible(true)}
                  />
                </SectionItem>
              ))}
            </SectionGrid>
          </div>
        </SectionBody>
      </Section>
      {pageData.benefitsSectionHeading && (
        <Section
          pt="90px"
          pb="0"
          xpt="60px"
          xpb="0"
          bgAfterWidth="100%"
          bgAfterHeight="138px"
          topAfter="auto"
          bottomAfter="0"
          bgAfter="linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(242,244,249,1) 100%)"
        >
          <div className="container">
            <SectionTitle mb="40px">
              {pageData.benefitsSectionHeading}
            </SectionTitle>
            {pageData.benefitsSectionDescription && (
              <SectionDescription
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.benefitsSectionDescription.childMarkdownRemark
                      .html,
                }}
              />
            )}
            <HalfCircle>
              {pageData.benefits.map(item => {
                return (
                  <HalfCircleItem>
                    <HalfCircleCaption>
                      <h3>{item.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description.childMarkdownRemark.html,
                        }}
                      />
                    </HalfCircleCaption>
                    <HalfCircleThumb>
                      <HalfCircleInset>
                        <GatsbyImage
                          image={item.image.gatsbyImageData}
                          alt={item.image.title}
                        />
                      </HalfCircleInset>
                    </HalfCircleThumb>
                  </HalfCircleItem>
                )
              })}
            </HalfCircle>
          </div>
        </Section>
      )}

      {pageData.contentText && (
        <Section
          pt="90px"
          pb="90px"
          xpt="60px"
          xpb="60px"
          bgBeforeWidth="100%"
          bgBeforeHeight="138px"
          topBefore="0"
          bgBefore="linear-gradient(to bottom,  rgba(242,244,249,1) 0%,rgba(255,255,255,0) 100%)"
        >
          <div className="container">
            <MainPanel>
              <LeftPannel>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: pageData.contentText.childMarkdownRemark.html,
                  }}
                />
              </LeftPannel>
              <RightPannel>
                <div className="isSticky">
                  <CustomQuote location={location} />
                </div>
              </RightPannel>
            </MainPanel>
          </div>
        </Section>
      )}
      <QuotePopup
        states={data.allContentfulState.edges}
        location={location}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default CategoryTypeTemplate

export const pageQuery = graphql`
  query CategoryTypeQuery($id: String!) {
    contentfulCategoryType(id: { eq: $id }) {
      name
      description {
        childMarkdownRemark {
          html
        }
      }
      url
      image {
        title
        gatsbyImageData
      }
      filterSectionTitle
      categoriesBelong {
        ... on ContentfulProductApplication {
          name
        }
        ... on ContentfulSubCategory {
          name
        }
      }
      metaTitle
      metaDescription
      headingText
      bannerDescription {
        childMarkdownRemark {
          html
        }
      }
      productsShadowText
      productsHeading
      benefitsSectionHeading
      benefitsSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      benefits {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData
        }
      }
      contentText {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
